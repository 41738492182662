import { apiConfig } from "src/apiconfig/ApiConfig";
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Grid,
  TextField,
  InputAdornment,
  Button,
  FormControl,
  Select,
  MenuItem,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import PaginationComponent from "src/component/PaginationComponent";
import axios from "axios";
import toast from "react-hot-toast";
import DataLoader from "src/component/DataLoader";

const useStyles = makeStyles((theme) => ({
  MainImgBox: {
    background: "url(images/Partners/PartnersMain.jpeg)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "250px",
    width: "100%",
    textDecoration: "none",

    "& h1": {
      fontSize: "72px",
      fontWeight: 500,
      lineHeight: "65px",
      fontFamily: "Alumni Sans",
      color: "rgba(255, 255, 255, 1)",

      padding: "0 16px",
      "@media(max-width:767px)": {
        fontSize: "60px !important",
        textAlign: "center",
      },
      "@media(max-width:500px)": {
        fontSize: "40px !important",
        lineHeight: "40px",
      },
    },
    "& span": {
      fontSize: "85px",
      fontWeight: 400,
      lineHeight: "30px",
      fontFamily: "Arizonia",
      textShadow: "0px 0px 8px rgba(255, 255, 255, 1)",
      color: "rgba(255, 255, 255, 1)",
      "@media(max-width:767px)": {
        fontSize: "65px !important",
      },
      "@media(max-width:500px)": {
        fontSize: "55px !important",
      },
    },
    "& h4": {
      fontWeight: 500,
      lineHeight: "65px",
      textAlign: "center",
      color: "#FFB443",
      "@media(max-width:500px)": {
        lineHeight: "32px",
      },
    },
  },

  secondContiner: {
    width: "80%",
    margin: "auto",
    "@media(max-width:600px)": {
      width: "100%",
    },
  },

  partnerBox: {
    padding: "40px 0px",
  },
  collegeListBox: {
    padding: "40px 0px",
    "& .selectItemBox": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  },
  mainBox: {
    cursor: "pointer",
    "& .patnersCardContainer": {
      background: "none",
      border: "1px solid #ECECEC",
      borderRadius: 0,
      background: "#fff",
    },

    "& .coursesName": {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      "& p": {
        marginLeft: "20px",
        borderRight: "1px solid #EDEDED",
        paddingRight: "10px",
      },
    },
    "& .flexControl": {
      display: "flex",
      "@media(max-width:699px)": {
        display: "block",
      },
    },
    "& .marginManage": {
      marginLeft: "24px",
      color: "black",
      "@media(max-width:699px)": {
        marginLeft: "0px",
        marginTop: "16px",
      },
    },
    "& .imgBox": {
      padding: "10px",
      border: "1px solid #B5B5B5",
      height: "100%",
      minHeight: "185px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    address: {
      fontSize: "10px",
    },
  },
}));

const Partners = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [states, setStates] = useState([]);

  const [totalPages, setNoofPages] = useState(1);
  const [page, setPage] = useState(1);
  const handleNext = () => {
    setPage(page + 1);
  };
  const handlePrevious = () => {
    setPage(page - 1);
  };
  useEffect(() => {
    const getState = async () => {
      const statesResponse = await axios.get("/json/states.json");
      const filteredStates = statesResponse?.data?.states.filter(
        (state) => state.country_id === "101"
      );
      setStates(filteredStates);
    };
    getState();
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const [search, setSearch] = useState("");
  const [locationState, setLocationState] = useState("");
  const [specialization, setSpecialization] = useState("Specialization");

  const [coeData, setCoeData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [Data, setData] = useState(coeData);
  const [isLoading, setisLoading] = useState(false);

  const CoePartnerList = async () => {
    setisLoading(true);
    try {
      const res = await axios({
        method: "POST",
        url: apiConfig.CoePartnerList,
        data: {
          page: page,
          search: search,
          state: locationState,
          limit: 15,
        },
      });
      if (res?.data?.responseCode == 200) {
        setCoeData(res?.data?.result?.docs);
        setFilteredData(res?.data?.result?.docs);
        setisLoading(false);
        setPage(res?.data?.result?.page);
        setNoofPages(res?.data?.result?.pages);
        setLocationState("")
        setSearch(" ");
        // toast.success(res?.data?.responseMessage);
      } else if (res?.data?.responseCode !== 200) {
        setisLoading(false);
        // toast.error(res?.data?.responseMessage);
      }
    } catch (err) {
      setisLoading(false);
      console.log("errlisat", err);
    }
  };

  useEffect(() => {
    CoePartnerList();
  }, [page]);

  const handleChangelocation = (event) => {
    setLocationState(event.target.value);
  };

  // const masterSearch = (event) => {
  //   setSearch(event.target.value);
  // };
  const masterSearch = (event) => {
    const value = event.target.value;
    const cleanedValue = value.replace(/ {2,}/g, ' ').trimStart();
    setSearch(cleanedValue);
  };
  

  // const handlenewSerch = () => {
  //   let searchText = search.trim().toLowerCase();
  //   let locationText = locationState.trim().toLowerCase();

  //   // Filter data based on search and location
  //   if (searchText.length !== 0 || locationText !== "location") {
  //     const filtered = coeData.filter((item) => {
  //       const nameMatch =
  //         searchText.length === 0 ||
  //         item.name.toLowerCase().includes(searchText);
  //       const stateMatch =
  //         locationText === "location" ||
  //         item.state.toLowerCase().includes(locationText);
  //       return nameMatch && stateMatch;
  //     });
  //     setFilteredData(filtered);
  //     setSearch(" ");
  //     setLocationState("Location");
  //   } else {
  //     CoePartnerList();
  //   }
  // };
  const handlenewSerch = () => {
    CoePartnerList(); // This will trigger the API call with updated search and locationState
  };
  const handleClick = (name, id) => {
    history.push(
      `/coe-partners/${name?.replace(/-/g, "_").replace(/\s+/g, "-").toLowerCase()}`,
      {
        Id: id,
      }
    );
  };

  return (
    <Box style={{ background: "#F9F9F9" }}>
      <Grid
        container
        component={Link}
        to="/about-coe-program"
        className={classes.MainImgBox}
      >
        <Grid
          item
          style={{ width: "100%", gap: "15px", background: "#000000B2" }}
          className="displayColumnCenter"
        >
          <Box className="">
            <Typography variant="h1">
              GyanBatua I & AI Centers Of Excellence
              {/* <span>Partners</span> */}
            </Typography>
          </Box>
          <Button variant="containedPrimary">
            <img
              src="/images/partnerBtnIcon.svg"
              style={{ height: "20px", width: "20px" }}
            ></img>
            <Typography variant="body2">
              &nbsp; &nbsp; Partner with us
            </Typography>
          </Button>
          {/* <Box>
            <Typography variant="h4">
              We’ve integrated with the below partners and more
            </Typography>
          </Box> */}
        </Grid>
      </Grid>
      <Box className={classes.secondContiner}>
        <Container maxWidth="100%">
          <Box className={classes.collegeListBox}>
            <Grid container spacing={2}>
              <Grid item lg={5} md={5} sm={6} xs={12}>
                <TextField
                  className={classes.searchbar}
                  variant="outlined"
                  value={search}
                  fullWidth
                  inputProps={{
                    style: {
                      height: "44px !important",
                      width: "40px !important",
                    },
                  }}
                  onChange={masterSearch}
                  placeholder="Search by College name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M14.6725 14.6412L19 19M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
                            stroke="#636880"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    className="selectItemBox"
                    value={locationState}
                    onChange={handleChangelocation}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: "200px",
                        },
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value="Location">
                      Select College Location
                    </MenuItem>
                    {states.map((item, i) => (
                      <MenuItem value={item?.name}>{item?.name} </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Button
                  variant="containedPrimary"
                  fullWidth
                  onClick={handlenewSerch}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
            {isLoading ? (
              <DataLoader />
            ) : (
              <Box mt={4} className={classes.mainBox}>
                {coeData && coeData.length === 0 ? (
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    mt={4}
                  >
                    <img
                      src="images/NaDataimg.png"
                      alt=""
                      style={{
                        height: "100px",
                        width: "100px",
                        margin: "0 auto",
                      }}
                    />
                    <Typography
                      variant="body1"
                      style={{
                        marginTop: "10px",
                        textAlign: "center",
                        fontSize: "18px",
                      }}
                    >
                      No Data Found
                    </Typography>
                  </Box>
                ) : (
                  <>
                    {coeData?.map((data, i) => {
                      return (
                        <Box mb={2}>
                          <Paper elevation={2} className="patnersCardContainer">
                            <>
                              {/* <Link to={data.url} style={{ textDecoration: "none" }}> */}
                              <Box
                                className="flexControl"
                                onClick={() => {
                                  handleClick(data?.name, data?._id);
                                }}
                              >
                                <Box className="imgBox">
                                  <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ width: "180px" }}
                                  >
                                    <img
                                      src={data.logo}
                                      alt=""
                                      width="100%"
                                      style={{ width: "151px" }}
                                    />
                                  </Box>
                                </Box>
                                <Box className="marginManage">
                                  <Box mb={1}>
                                    <Typography
                                      variant="h3"
                                      style={{
                                        fontWeight: "500",
                                        color: "#0856CC",
                                      }}
                                    >
                                      {`${data?.name},${" "}${data?.city}`}
                                    </Typography>
                                  </Box>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    mb={1}
                                  >
                                    <Typography>Address:</Typography>
                                    <Box style={{ marginLeft: "5px" }}>
                                      <Typography
                                        variant="body1"
                                        color="textSecondary"
                                      >
                                        {data?.address}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  {/* <Box display="flex" alignItems="center" mb={1}>
                                <Typography variant="body1">
                                  CoE No.:
                                </Typography>
                                <Box style={{ marginLeft: "5px" }}>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    {data.coenumber}
                                  </Typography>
                                </Box>
                              </Box> */}
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    mb={1}
                                  >
                                    <Typography variant="body1">
                                      City:
                                    </Typography>
                                    <Box style={{ marginLeft: "5px" }}>
                                      <Typography
                                        variant="body1"
                                        color="textSecondary"
                                      >
                                        {data?.city}
                                      </Typography>
                                    </Box>
                                  </Box>

                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    mb={1}
                                  >
                                    <Typography variant="body1">
                                      State:
                                    </Typography>
                                    <Box style={{ marginLeft: "5px" }}>
                                      <Typography
                                        variant="body1"
                                        color="textSecondary"
                                      >
                                        {data?.state}
                                      </Typography>
                                    </Box>
                                  </Box>

                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    mb={1}
                                  >
                                    <Typography variant="body1">
                                      Affiliation:
                                    </Typography>
                                    <Box style={{ marginLeft: "5px" }}>
                                      <Typography
                                        variant="body1"
                                        color="textSecondary"
                                      >
                                        {data?.affiliation}
                                      </Typography>
                                    </Box>
                                  </Box>

                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    mb={1}
                                  >
                                    <Typography variant="body1">
                                      I & AI CoE Number:
                                    </Typography>
                                    <Box style={{ marginLeft: "5px" }}>
                                      <Typography
                                        variant="body1"
                                        color="textSecondary"
                                      >
                                        {data?.coeNumber}
                                      </Typography>
                                    </Box>
                                  </Box>

                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    mb={1}
                                  >
                                    <Typography variant="body1">
                                      Established:
                                    </Typography>
                                    <Box style={{ marginLeft: "5px" }}>
                                      <Typography
                                        variant="body1"
                                        color="textSecondary"
                                      >
                                        {data?.dateMou != "null"
                                          ? new Date(
                                              data.dateMou
                                            ).toLocaleDateString("en-US", {
                                              year: "numeric",
                                              month: "long",
                                              day: "numeric",
                                            })
                                          : "--"}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                              {/* </Link> */}
                            </>
                          </Paper>
                        </Box>
                      );
                    })}
                  </>
                )}
              </Box>
            )}
            <Box mt={2}>
              {totalPages > 1 && (
                <>
                  <PaginationComponent
                    count={totalPages}
                    page={page}
                    handleChange={(e, value) => setPage(value)}
                    handlePrevious={handlePrevious}
                    handleNext={handleNext}
                  />
                </>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Partners;
