import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory,useLocation } from "react-router-dom";
import Footer from "./Footer";
import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: "#FAFAFA",
  },
  MainLayout: {
    minHeight: "calc(100vh - 415px)",
    // backgroundColor: "#FAFAFA",
    // height: "100vh",
  },

  MainLayoutWithHeight: {
    minHeight: "calc(100vh - 415px)",
    backgroundColor: "#FAFAFA",
    height: "100vh",
    overflow: 'hidden',
  },

  paddingWrapper: {
    paddingTop: "80px",
    [theme.breakpoints.down("sm")]:{
      paddingTop:'72px',
    },

    [theme.breakpoints.down("xs")]:{
      paddingTop:'56px'
    },
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();

  const routesWithHeader = [
    "/login",
    "/register",
    "/login-password",
    "/reset-password",
    "/register-password",
    "/forgot",
    "/verifyOtp",
    "/verify-otp",
  ];

  const showTopBar = routesWithHeader.includes(history.location.pathname);
  const location = useLocation();
  const [previousPath, setPreviousPath] = useState(location.pathname); 
  const homeRoute = window.location.pathname === "/";
  const fullPath = location.pathname;
  // Extract '/skill-drops'
  const basePath = fullPath.startsWith('/skill-drops') ? '/skill-drops' : '';
  console.log("path is=====>>>0",basePath)
  useEffect(() => {
    // Check if the current path starts with /skill-drops/
    const isCurrentSkillDropsRoute = location.pathname.startsWith("/skill-drops");
    console.log("path is=====>>>1",isCurrentSkillDropsRoute, location.pathname)
    // Clear localStorage if navigating away from /skill-drops route
    if (!isCurrentSkillDropsRoute) {
        // Only clear localStorage if we were previously on a skill drops route
        console.log("path is=====>>>2",isCurrentSkillDropsRoute, location.pathname)
        localStorage.removeItem("selectedCategory");
        localStorage.removeItem("selectedSubCategory");
        console.log("path is=====>>>3",isCurrentSkillDropsRoute, location.pathname)
    }

    // Update the previous path for the next route change
    setPreviousPath(location.pathname);
}, [location.pathname, previousPath]);



  return (
    <div className={classes.root}>
      {showTopBar ? (
        <>
          <TopBar />
          <div className={homeRoute ? classes.paddingWrapper : null}>
            <div
              className={
                showTopBar ? classes.MainLayoutWithHeight : classes.MainLayout
              }
            >
              {children}
            </div>
          </div>
        </>
      ) : (
        <>
          <TopBar />
          <div className={homeRoute ? classes.paddingWrapper : null}>
            <div className={classes.MainLayout}>{children}</div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
};

export default MainLayout;
